import type { AnsweredQuestionnaire } from "@9amhealth/openapi";
import { useCallback, useEffect } from "react";
import { RISK_QUIZ_QUESTIONNAIRE_ID, PTHW_BINARY_RATING_QUESTIONNAIRE_ID } from "src/constants/misc";
import createTrackEvent from "src/lib/createTrackEvent";
import reportErrorSentry from "src/lib/reportErrorSentry";
import { toast, tracker } from "src/state/state";
import type { Answer } from "src/ui/components/Feedback/Feedback";

export default function useQuestionnaireTracking() {
  type AnsweredQuestionnaireWithCustomVars = AnsweredQuestionnaire & {
    customFormVariables: {
      custom_score?: number;
      partner?: string;
      rating_subject_uid?: string;
      rating_subject_title?: string;
    };
  };

  const handleQuestionnaireSaved = useCallback((event: Event) => {
    const customEvent = event as CustomEvent<AnsweredQuestionnaireWithCustomVars>;
    
    const riskQuizEmailQuestionId = "iLAVtu3XKhLr";

    if (customEvent.detail.questionnaireRef.id === RISK_QUIZ_QUESTIONNAIRE_ID) {
      const answers = (customEvent.detail.answers.json ?? []) as Answer[];
      const emailQuestion = answers.find(
        (item) => item.questionId === riskQuizEmailQuestionId
      );
      const email = emailQuestion?.fieldValue;
      const score = customEvent.detail.customFormVariables.custom_score;
      const partner = customEvent.detail.customFormVariables.partner;

      if (email && typeof score === "number") {
        tracker.track(createTrackEvent("Risk Quiz Completed"), {
          data: {
            "Marketing Email": email,
            Score: score,
            Partner: partner
          }
        });
      } else {
        toast.error("error_form_risk_quiz");
        reportErrorSentry(
          new Error(
            "No email and/or score provided after completing the risk quiz"
          )
        );
      }
    }

    if (customEvent.detail.questionnaireRef.id === PTHW_BINARY_RATING_QUESTIONNAIRE_ID) {
      const ratingSubjectUid = customEvent.detail.customFormVariables.rating_subject_uid;
      const ratingSubjectTitle = customEvent.detail.customFormVariables.rating_subject_title;
      const answers = (customEvent.detail.answers.json ?? []) as Answer[];
      
      // Get the rating value from the first answer (assuming it's a binary rating)
      const ratingAnswer = answers[0];
      const rating = ratingAnswer?.fieldValue;

      if (ratingSubjectUid && ratingSubjectTitle && rating !== undefined) {
        tracker.track(createTrackEvent("Module Rating Submitted"), {
          data: {
            "Rating Subject UID": ratingSubjectUid,
            "Rating Subject Title": ratingSubjectTitle,
            "Rating": rating
          }
        });
      } else {
        reportErrorSentry(
          new Error(
            "Missing required data for module rating tracking"
          )
        );
      }
    }
  }, []);

  useEffect(() => {
    document.addEventListener(
      "nineQuestionnaireSaved",
      handleQuestionnaireSaved
    );

    return () => {
      document.removeEventListener(
        "nineQuestionnaireSaved",
        handleQuestionnaireSaved
      );
    };
  }, []);
}
